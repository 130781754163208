@import "./creditcard";
@import "./layout.scss";
@import "./calendar.scss";
@import "./formList.scss";

.language {
	border: none;
	outline: none;
	background-color: #026885;
	color: #fff;
}
