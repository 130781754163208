.map {
	height: 60vh;
	width: 100%;
	position: relative;
	.address {
		position: absolute;
		left: 0;
		right: 0;
		z-index: 2;
		bottom: 80px;
		font-weight: bold;
		text-align: center;
	}
	.ant-btn.ant-btn-primary {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 20px;
		margin: 0 auto;
		z-index: 2;
		width: fit-content;
	}
	.location-wrapper {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		transform: translate(-50%, -50%);
		& > span {
			font-size: 40px;
			color: #3e79f7;
		}
	}
	.ymaps-2-1-79-map-copyrights-promo,
	.ymaps-2-1-79-copyright {
		display: none;
	}
}

.map-modal {
	width: 80% !important;
	height: 80%;
	.ant-modal-content,
	.ant-modal-body,
	.map,
	.ymaps-2-1-79-map {
		width: 100%!important;
		height: 100%!important;
	}
}
